$(document).ready(function () {
  var bodyTag = $('body')

  // Iterate over each select element
  $('.dropdown_privat').each(function () {
    // This class is being added here so it is only applied in privat-frontpages
    // where dropdown_privat menus are used
    bodyTag.addClass('gradient-bg')

    // Cache the number of options
    var $this = $(this)
    var numberOfOptions = $(this).children('option').length

    // Hides the select element
    $this.addClass('s-hidden')

    // Wrap the select element in a div
    $this.wrap('<div class="dropdown"></div>')

    // Insert a styled div to sit over the top of the hidden select element
    $this.after('<div class="styledSelect"></div>')

    // Cache the styled div
    var $styledSelect = $this.next('div.styledSelect')

    // Show the first select option in the styled div
    $styledSelect.text($this.children('option').eq(0).text())

    // Insert an unordered list after the styled div and also cache the list
    var $list = $('<ul />', {
      class: 'options'
    }).insertAfter($styledSelect)

    // Insert a list item into the unordered list for each select option
    for (var i = 0; i < numberOfOptions; i++) {
      $('<li />', {
        text: $this.children('option').eq(i).text(),
        rel: $this.children('option').eq(i).val()
      }).appendTo($list)
    }

    // Cache the list items
    var $listItems = $list.children('li')

    // Show the unordered list when the styled div is clicked (also hides it if the div is clicked again)
    $styledSelect.click(function (e) {
      e.stopPropagation()
      $('div.styledSelect.active').not(this).each(function () {
        $(this).removeClass('active').next('ul.options').slideUp('fast')
      })
      $(this).toggleClass('active').next('ul.options').slideToggle('fast')
    })

    // Hides the unordered list when a list item is clicked and updates the styled div to show the selected list item
    // Updates the select element to have the value of the equivalent option
    $listItems.click(function (e) {
      e.stopPropagation()
      $styledSelect.text($(this).text()).removeClass('active')
      $this.val($(this).attr('rel'))
      $list.hide()

      // Get selected item val

      // alert($this.val());
    })

    // Hides the unordered list when clicking outside of it
    $(document).click(function () {
      $styledSelect.removeClass('active')
      $list.slideUp('fast')
    })
  })

  // Trying to grab variables from HTML in case they exist
  let currentServiceList
  let currentOfficeList
  try {
    currentServiceList = serviceList
    currentOfficeList = officeList
  } catch (e) {}

  var dropPrivatService = $('#service_dropdown') // Dropdown for the service areas
  var dropPrivatOffice = $('#office_dropdown') // Dropdown for the offices
  var privatForm = $('#privat_form') // Form as a whole
  var privatButton = $('#privat_button') // Button for the form
  var serviceSelected = $('.styledSelect')[0] // The service selected at the moment
  var officeSelected = $('.styledSelect')[1] // The office seleced ar the moment

  var actionUrl = ''

  // Setting the button to unactive until office is selected
  $(privatButton).attr('disabled', true)

  // Holding a variable for the id of the service selected and a list for all the offices matching the service
  var selectedServiceId = undefined
  var officeToShow = []

  // Listening for changes on the service dropdown
  var observer = new MutationObserver(function () {
    // Cleaning the list of selected offices
    officeToShow = []

    // Saving the id of the selected service
    currentServiceList && currentServiceList.map((item) => {
      if (item.name == serviceSelected.textContent) {
        selectedServiceId = item.id
      }
    })

    // Pushing all the offices that have the selected service
    currentOfficeList && currentOfficeList.map((offs) => {
      offs.servicesId.map((ids) => {
        if (ids == selectedServiceId) {
          officeToShow.push(offs)
        }
      })
    })

    // Selecting the list to show the matching offices
    var $list = $('ul.options')[1]
    $list.innerHTML = ''

    // Feeding the office content to the list
    for (var i = 0; i < officeToShow.length; i++) {
      var lis = $('<li />', { class: 'updatedOffices' })
      $(lis).text(officeToShow[i].name)
      $(lis).attr('value', officeToShow[i].url)
      $(lis).appendTo($list)
    }

    // Getting individual items of the list
    var $listItems = $('li.updatedOffices')

    // If any item is clicked then...
    $listItems.click(function (e) {
      e.stopPropagation()

      // The selected office changes its text
      officeSelected.innerText = $(this)[0].innerText

      // The action for the form redirects to the offices' url
      // $(privatForm).attr( "action" ,$(this)[0].attributes.value.value);
      actionUrl = $(this)[0].attributes.value.value

      // Reactive the button
      $(privatButton).attr('disabled', false)

      // Closes the open dropdown
      $(officeSelected).removeClass('active')
      $($list).hide()
    })
  })
  try {
    observer.observe(serviceSelected, { childList: true })
  } catch (err) {

  }

  $(privatButton).on('click', function (e) {
    window.open(actionUrl, '_self')
  })

  // First load of page
  // Saving the id of the selected service
  currentServiceList && currentServiceList.map((item) => {
    if (item.name == serviceSelected.textContent) {
      selectedServiceId = item.id
    }
  })

  // Pushing all the offices that have the selected service
  currentOfficeList && currentOfficeList.map((offs) => {
    offs.servicesId.map((ids) => {
      if (ids == selectedServiceId) {
        officeToShow.push(offs)
      }
    })
  })

  // Selecting the list to show the matching offices
  var $list = $('ul.options')[1]
  $list.innerHTML = ''

  // Feeding the office content to the list
  for (var i = 0; i < officeToShow.length; i++) {
    var lis = $('<li />', { class: 'updatedOffices' })
    $(lis).text(officeToShow[i].name)
    $(lis).attr('value', officeToShow[i].url)
    $(lis).appendTo($list)
  }

  // Getting individual items of the list
  var $listItems = $('li.updatedOffices')

  // If any item is clicked then...
  $listItems.click(function (e) {
    e.stopPropagation()

    // The selected office changes its text
    officeSelected.innerText = $(this)[0].innerText

    // The action for the form redirects to the offices' url
    // $(privatForm).attr( "action" ,$(this)[0].attributes.value.value);
    actionUrl = $(this)[0].attributes.value.value

    // Reactive the button
    $(privatButton).attr('disabled', false)

    // Closes the open dropdown
    $(officeSelected).removeClass('active')
    $($list).hide()
  })
})
